import React, { Component } from 'react'
// import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
// import {Container} from "@material-ui/core";
// import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
// import DemoFooter from "components/Footers/DemoFooter.js";
// import Sidebar from 'components/Navbars/Sidebar';

// import { FaHeart, FaBars } from 'react-icons/fa';
import VideoSection from './Sections/VideoSection';



function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length >= 2) return parts.pop().split(";").shift();
  }
  

function Video(props) {
    const [activeTab, setActiveTab] = React.useState("1");
    const [collapsed, setCollapsed] = React.useState(false);
    const [toggled, setToggled] = React.useState(false);

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const handleCollapsedChange = (checked) => {
        setCollapsed(checked);
    };
    const handleToggleSidebar = (value) => {
        setToggled(value);
    };
    // document.documentElement.classList.remove("nav-open");
    // React.useEffect(() => {
    //   document.body.classList.add("landing-page");
    //   return function cleanup() {
    //     document.body.classList.remove("landing-page");
    //   };
    // });
    
    var role = getCookie('RoleType');
    if(role=='WebSiteGuest' || role == 'Admin'){
    }
    else{

      window.location='https://login.learnisl.com/';
    }
    // window.onbeforeunload = function() {
    //       localStorage.removeItem('RoleType');
    //       return '';
    //     };

    //var word = props.match.params.word;
       console.log(props.match.params);

    return (
        <>
        
            {/* <ExamplesNavbar /> */}
            {/* <ProfilePageHeader /> */}
            <div className="section profile-content">
                    <VideoSection  {...props}/>
            </div>
            {/* <DemoFooter /> */}
        </>
    );
}

export default Video;
