import API from './API';

import axios from 'axios';

export default class dbconenction {
    dbconenction() {
        console.log('dbconnection');
    }
    async authenticate(uemail, upswd) {
        var valid = 0;

        const data = new FormData();
        data.append("email", uemail);
        data.append("pswd", upswd);

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
        await axios.post(API.Apiserveraddress + '/User/Authenticate.php', data, {
            headers: headers
        }
        )
            .then((res) => {
                res = res.data;
                console.log(res);
                if (res.Success === true) {
                    valid = res;
                }
                else {
                    alert("Error: Incorrect Username or Password");
                }

            }).catch(function (Error) {
                //this.toggleLoadingProgress();
                alert("Could not connect to the server ");
            });

        return valid;
    }

    async register(name, uemail, upswd, umobile) {
        var response = "";
        var request = API.Apiserveraddress + 'register_student.php';

        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: JSON.stringify({ uname: name, email: uemail, pswd: upswd, mobile: umobile })
        };

        await fetch(request, requestOptions)
            .then(response => response.json())
            .then(data => { response = data });
        console.log('result ' + response);
        return response;
    }



    async loadAllLectures() {

        const data = new FormData();
        //data.append("jwt", this.getJwt());

        var result = null;
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
        await axios.post(API.Apiserveraddress + 'GetAllLectures.php', data, {
            headers: headers
        }

        )
            .then((res) => {
                result = res.data;

            }).catch(function (Error) {
                alert("Could not connect to the server ");
            });

        return result;
    }
    
    async loadItemsByWordSubCategory(WSCID, CID) {

        const data = new FormData();
        //data.append("jwt", this.getJwt());
        data.append("WSCID", WSCID);
        data.append("CID", CID);

        var result = null;
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
        await axios.post(API.Apiserveraddress + 'GetItemsByWordSubCategory.php', data, {
            headers: headers
        }

        )
            .then((res) => {
                result = res.data;

            }).catch(function (Error) {
                alert("Could not connect to the server ");
            });

        return result;
    }

    async upload(Title, SbjID, UFile, UClass, progress) {

        var result = 0;
        console.log(SbjID);

        const data = new FormData();
        data.append("Title", Title);
        data.append("SbjID", SbjID);
        data.append("ClassN", UClass);
        data.append("file", UFile);
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
        

        await axios.post(API.Apiserveraddress + 'Upload.php', data, {crossDomain: true,
            headers: headers,
         //   onUploadProgress: progressEvent => {
           //     let uploaded = Math.round(progressEvent.loaded / progressEvent.total * 100);
            //    progress(uploaded);

            //}
        }).then((res) => {
            // console.log(res);
            result = res.data;

        }).catch(function (Error) {
            alert("Could not connect to the server ");
        });

        return result;
    }
    async deleteLecture(id)
    {
        const data = new FormData();
        //data.append("jwt", this.getJwt());
        data.append("id", id);

        var result = null;
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
        await axios.post(API.Apiserveraddress + 'DeleteLecture.php', data, {
            headers: headers
        }

        )
            .then((res) => {
                result = res.data;

            }).catch(function (Error) {
                alert("Could not connect to the server ");
            });

        return result;       
    }

    getJwt() {
        return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9leGFtcGxlLm9yZyIsImF1ZCI6Imh0dHA6XC9cL2V4YW1wbGUub3JnIiwiaWF0IjoxMzU2OTk5NTI0LCJuYmYiOjEzNTcwMDAwMDAsImRhdGEiOnsidWlkIjoiMiIsInVuYW1lIjoiYW1hbiIsImN1cnJlbnRfZGF0ZSI6IjIwMjAtMDctMTUgMTQ6NDc6MTEiLCJlbWFpbCI6ImFiY0BnbWFpbC5jb20ifX0.i2CIegJ3B_s8vvoCbEWasCGC0FDq1333OEHiKfke8QY";
    }
}