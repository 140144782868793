
import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
// import "assets/css/bootstrap.min.css";
// import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";


// pages
// import Index from "views/Index.js";
import Video from "views/Video.js";
// import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
// import WordText from "views/WordText.js";
// import NucleoIcons from "views/NucleoIcons.js";
// import TempIndex from "views/TempIndex.js";
// import ProfilePage from "views/examples/ProfilePage.js";
// import RegisterPage from "views/examples/RegisterPage.js";
// others



const routing = (
  <BrowserRouter>
    <Switch>
       {/* <Route exact path="/:lang?" component={Index} />  */}
       <Route path="/index/:category?" component={(props) => <Video {...props} />} />

       <Route path="/search/:lang/:word" component={(props) => <Video {...props} />} />
    


    </Switch>
  </BrowserRouter>
)

ReactDOM.render(
  routing,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();




// ReactDOM.render(
//   <BrowserRouter>
//     <Switch>
//       <Route path="/:category?" render={(props) => <Video {...props} />} />

//       <Route path="/search/:lang/:word" render={(props) => <Video {...props} />} />
//       {/* <Route path="/video" render={(props) => <Video {...props} />} />
//       <Route path="/wordText" render={(props) => <WordText {...props} />} />
    
//       <Route
//         path="/register-page"
//         render={(props) => <RegisterPage {...props} />}
//       /> */}
//       {/* <Redirect to="/index" /> */}
//     </Switch>
//   </BrowserRouter>,
//   document.getElementById("root")
// );
