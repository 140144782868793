import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AppBar, Button, Drawer, Hidden, IconButton, Toolbar, Collapse, Box, Menu, MenuItem, Grid } from '@material-ui/core';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Typography, Link } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// import Colors from '. constants/Colors';
import Data from 'constants/DataSidebar.json';
import LecturePlaySection from './LecturePlaySection'
import { Redirect } from 'react-router-dom';
import Colors from 'constants/Colors';



const drawerWidth = 240;
const useStyles = theme => ({
    typography: {
        flexGrow: 1,
        align: "center"
    },


    sideBarLink: {
        color: '#000',
    },
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },

    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },

    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: Colors.themeblue,
        color:'#fff',
        position: "inherit",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    closeMenuButton: {
        marginRight: 'auto',
        marginLeft: 0,
    },
    category:{
        display: "none",
      },
      
      

});
class VideoSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            open: false,
            currentMenu: "",
            auth: true,
        }
        this.child = React.createRef();
    }
    componentDidMount() { 
       var cat = this.props.match.params.category;
       if(cat ==="Alphabets"){
           this.handleSubjectClick(6,10,"A to Z","Alphabets");
       }
       else if(cat === "Story"){
        this.handleSubjectClick(8,12,"Greedy Dog","Story");

       }
       else if(cat === "Traffic"){

        this.handleSubjectClick(1,1,"Mandatory Signs","Traffic");
    }
    else if(cat === "Directions"){
        this.handleSubjectClick(4,8,"Directions","Directions");

    }
    else if(cat === "Greetings"){
        this.handleSubjectClick(7,11,"Most Common","Greetings");

    }
    else if(cat === "Sentences"){
        this.handleSubjectClick(14,23,"Part-1","Important Sentences");

    }
    else if(cat === "Weather"){
        this.handleSubjectClick(13,20,"Weather","Essay");

    }
    else if(cat === "Important Days"){
        this.handleSubjectClick(5,9,"National & Inter-national","Important Days");

    }
    else if(cat === "Good Habits"){
        this.handleSubjectClick(3,5,"In General","Good Habits");

    }
    else if(cat === "Essay"){
        this.handleSubjectClick(13,21,"People Who Help Us","Essay");

    }

    
       this.handleClick(cat);

     }

    handleClick = (key) => {
        if (this.state.currentMenu === key) {
            this.setState({
                open: false,
            })
        }
        else {
            this.setState({
                open: true,
            })

        }

        this.setState({
            currentMenu: key,
        })
    };
    handleDrawerToggle = () => {
        this.setState({
            mobileOpen: !(this.state.mobileOpen)
        })
    }

    handleSubjectClick = (CID, WSCID, SubTitle, MainTitle) => {
        this.child.current.loadAllLectures(CID, WSCID, SubTitle, MainTitle);
    }
    handleMenuPopup = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            openMenuPopup: true,
        });

    };

    handleCloseMenuPopup = () => {
        this.setState({
            anchorEl: null,
            openMenuPopup: false,
        })
    };
    handleLogout = () => {
        this.handleCloseMenuPopup();
        localStorage.clear();
    }


    render() {
        const { classes } = this.props;

        //const theme = useTheme();


        // document.oncontextmenu = function (e) {
        //     console.log(e.button);
        //     if (e.button === 2) {
        //            e.preventDefault();
        //          return false;
        //     }

        // }

        const drawer = (
            <div>
                <List>


                    {
                        Data.map((data) => {
                            return (
                                <Fragment key={data.MainTitle}>
                                    <ListItem button onClick={() => { this.handleClick(data.MainTitle) }}>
                                        <ListItemIcon>
                                            <AddCircleIcon style={{color:'#fff'}}/>
                                        </ListItemIcon>
                                        <ListItemText primary={data.MainTitle} />
                                        {(this.state.currentMenu === data.MainTitle && this.state.open) ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={(this.state.currentMenu === data.MainTitle && this.state.open)} timeout="auto" unmountOnExit >

                                        <List component="div" disablePadding>
                                            {
                                                data.Subject.map((SubCat) => {
                                                    return (
                                                        <ListItem button className={classes.nested} onClick={() => { this.handleSubjectClick(SubCat.CID, SubCat.WSCID, SubCat.SubTitle, data.MainTitle) }} key={SubCat.WSCID}>
                                                            <ListItemIcon>
                                                                <StarBorder style={{color:'#fff'}}/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={SubCat.SubTitle} />
                                                        </ListItem>

                                                    )
                                                })}
                                        </List>
                                    </Collapse>
                                </Fragment>
                            )
                        })



                    }

                </List>

            </div >
        );

        // if (localStorage.getItem('name') === null) {
        //     return <Redirect to="/SignIn" push={true} />
        // }

        return (

            <div className={classes.root}>
                <CssBaseline />

                <nav className={classes.drawer}>
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor={"left"} //{theme.direction === 'rtl' ? 'right' : 'left'}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}

                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            <IconButton onClick={this.state.handleDrawerToggle} className={classes.closeMenuButton}>
                                <CloseIcon />
                            </IconButton>
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            className={classes.drawer}
                            variant="permanent"
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <div className={classes.toolbar} />
                            {drawer}
                        </Drawer>
                    </Hidden>

                </nav>
                {/* Content Area */}
                <div className={classes.content}>
                    {/* <div className={classes.toolbar} /> */}
                    <Grid style={{textAlign:'center'}}>
                        
                        <Button onClick={this.handleDrawerToggle} className="category">Category</Button>
                    </Grid>
                    <Fragment>

                        <LecturePlaySection ref={this.child} />
                    </Fragment>
                </div>
            </div>
        );
    }
}

VideoSection.propTypes = {

    container: PropTypes.object,
};
export default withStyles(useStyles)(VideoSection);
