import React, { Component } from 'react'
import { Chip, Paper, Grid, withStyles, ListItem, List, ListItemText, ListItemIcon, Card, CardContent, CardHeader, Avatar, Button, Divider, Tooltip } from '@material-ui/core'
import ImportContacts from '@material-ui/icons/ImportContacts';
import ReactPlayer from 'react-player';
import Colors from '../../constants/Colors';
import DBConnection from '../../constants/DBConnection';
import API from '../../constants/API';
const dbCon = new DBConnection();
const useStyles = theme => ({
    thumbnail: {
        padding: 3,
        borderRadius: 5,
    },
    lectureList: {
        padding: 0,
    },
    avatar: {
        background: Colors.themeyellow,
        color: Colors.themeblack,
    },
    videoPlayer: {
        //   minHeight:200,
    },
    imgThumbnail: {
        borderWidth: "medium",
        borderStyle: "solid",
        borderColor: Colors.themeblue,
        borderRadius: 4,
        padding: 5,
        width: 100,
        height: "auto",
        right: 0,
        left: 0,
        margin: "auto",
        display: "inherit"
    },
   
    imgBox:{
        width: 70,
        height: 70,
        overflow:"hidden",
        padding: 5,
        borderStyle: "solid",
        borderColor: Colors.themegrey,
        borderRadius: 4,
        borderWidth: "medium",
        display: "inline-block",
        margin: 5,
    },
    imgIcons: {
        width:"100%",
    }


})
class LecturePlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSubTitle: "",
            currentTitle: "",
            currentLectureVideo: '#',
            currentLectureTitle: "",
            currentLectureID: 0,
            lectures: null,
        }
    }

    loadAllLectures = async (CID, WSCID, SubTitle, Title) => {
        this.setState({
            currentSubTitle: SubTitle,
            currentTitle: Title,
        })
        const result = await dbCon.loadItemsByWordSubCategory(WSCID, CID);
        //console.log(result);
        if (result.length > 0) {
            console.log("not null" + result);
            this.setState({
                lectures: result,
            });
        }
        else {
            console.log("Null");
            this.setState({
                lectures: null
            })
        }

        //  console.log(result);
    }
    onSelectLecture = (row) => {
        this.setState({
            currentLectureTitle: row.EWord,
            currentLectureID: row.WID,
            currentLectureVideo: API.WebServerAddress + row.VideoPath,
        })
        console.log(row);
    }

    ref = player => {
        this.player = player
    }

    render() {
        const { classes } = this.props;
        const ref = React.createRef();
        return (

            <Grid container spacing={3}>
                <Grid item xs={12} md={7} alignItems="center">
                    <Paper
                        className={classes.thumbnail}
                        variant="outlined"
                        style={{ textAlign: "center" }}
                    >
                        <ReactPlayer
                            ref={this.ref}
                            playsinline
                            width={"100%"}
                            height={"100%"}
                            controls={true}
                            playing={true}
                            url={this.state.currentLectureVideo}
                            className={classes.videoPlayer}
                            config={{
                                file: {
                                    attributes: {
                                        onContextMenu: e => e.preventDefault(),
                                        controlsList: 'nodownload'
                                    }
                                }
                            }}
                        />
                        <Divider />
                        
                        <Button onClick={() => { this.player.seekTo(parseFloat(0)); this.player.playing = true }} style={{ backgroundColor: Colors.themeblue, color: "#fff", margin: 5 }}>Play Again</Button>
                    </Paper>
                </Grid>
                <Grid item md={5} xs={12}>
                    <Card
                        className={classes.thumbnail}

                    >
                        <CardHeader
                            style={{ backgroundColor: Colors.themeblue, color: "#fff" }}
                            avatar={
                                <Avatar aria-label="recipe" className={classes.avatar}>
                                    ISL
                            </Avatar>
                            }
                            title={this.state.currentSubTitle}
                            subheader={this.state.currentTitle}
                            subheaderTypographyProps={{color:'#fff'}}
                        />

                        <CardContent style={{ textAlign: 'Center' }}>
                            {/* @if (ViewBag.ImgPath !=null) */}

                            <p>
                                <img src={require("assets/img/learnisl/video-thumbnail.png")} className={classes.imgThumbnail} alt="Sign" />
                            </p>
                            <Chip label={this.state.currentLectureTitle} style={{background:Colors.themeblue, color:'#fff'}} />
                            <hr />

                            <List>
                                {this.state.lectures != null ? this.state.lectures.map((row) => (
                                    (row.Icon == "F" &&
                                        <ListItem key={row.WID} className={classes.lectureList} onClick={() => { this.onSelectLecture(row) }}>
                                            <ListItemIcon>
                                                <ImportContacts />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {row.EWord}
                                            </ListItemText>
                                        </ListItem>)
                                    ||
                                    (
                                        row.Icon == "T" &&
                                        <Tooltip title={row.EWord}>
                                            <div onClick={() => { this.onSelectLecture(row) }} className={classes.imgBox}>

                                                <img src={API.WebServerAddress + row.ImgPath} className={classes.imgIcons} alt="Sign" />

                                            </div>
                                        </Tooltip>


                                    )



                                )) : null}

                            </List>
                        </CardContent>

                    </Card>

                </Grid>

            </Grid>

        )
    }
}

export default withStyles(useStyles)(LecturePlay);